import { useEffect, useState } from 'react';

import { isServer } from '@/components/shared/utility/browser';

/**
 * Custom hook to check if a given media query matches the current viewport.
 *
 * @remarks
 * This hook is server-side rendering (SSR) friendly. It returns `undefined` on the server side.
 *
 * @param query - The media query to be evaluated.
 * @returns A boolean value indicating whether the media query matches the current viewport or `undefined` on the server side.
 */
export const useMediaQuery = (query: string): boolean | undefined => {
  const [matches, setMatches] = useState<boolean | undefined>(
    isServer ? undefined : window.matchMedia(query).matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const onMediaQueryChange = () => {
      setMatches(mediaQuery.matches);
    };

    // Check if addEventListener is available, otherwise use addListener
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', onMediaQueryChange);
    } else {
      mediaQuery.addListener(onMediaQueryChange);
    }

    return () => {
      // Check if removeEventListener is available, otherwise use removeListener
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', onMediaQueryChange);
      } else {
        mediaQuery.removeListener(onMediaQueryChange);
      }
    };
  }, [query]);

  return matches;
};
